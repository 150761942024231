import request from '@/utils/request'

//退款待选商品集合
export function refundgoodsList(orderNum) {
    return request({
      url: '/app/refund/goods/' + orderNum,
      method: 'get'
    })
}
//提交退货申请
export function submintRefund(data) {
    return request({
      url: '/app/refund/apply',
      method: 'post',
      data: data
    })
}