<template>
    <div>
        <div style="margin-bottom:60px;">
            <div class="main" v-for="main in goods" :key="main.id" >
                <div v-for="item in main.detailList" :key="item.id" class="category">
                    <van-card
                        :title="item.goodsName"
                        :desc="item.goodsNameEn"
                        class="goods-card"
                        currency='￡'
                        @click-thumb="changeImg($event,item)"
                    >
                        <template #price>
                            <div class="card_price">
                                ￡<span style="font-size:16px;">{{item.unitPrice}}</span> 
                                <span class="vatclass" v-if="item.vat == 1"> +VAT</span>
                            </div>
                        </template>
                        <template #thumb>
                            <van-image  :src="item.cover" width="100%" height="100%"/>
                        </template>
                        <template #tags>
                            <span style="font-size:14px;color:#888;margin-top:6px;display: inline-block">規格：{{item.unitPrice}}/{{item.unit}}</span><br/>
                            <span style="font-size:14px;color:#888;margin-top:6px;margin-bottom:6px;display: inline-block">購買：{{item.qty}}</span>
                        </template>
                        <template #num>
                        <span>
                            <div class="stepper_btn">
                            <div style="color:#000;font-size:12px;"></div>
                            <van-stepper
                            v-model="item.refundQty"
                            button-size="20"
                            min="0"
                            :max="item.remainQty"
                            integer
                            default-value="0"
                            @plus="plusCommodity(item)"
                            @minus="minusCommodity(item)"
                            @blur="changeNum(item)"
                            @overlimit="limitTip(item)"
                            style="float:right"
                            v-if="item.refundable == 1"
                            />
                            </div>
                        </span>
                        </template>
                    </van-card>
                </div>
            </div>
        </div>
        <div class="footer_button">
            <van-button type="info" style="border:none;font-size:14px;width:100%; text-align:center;" @click="submintRefund">確認退貨</van-button>
        </div>
    </div>
</template>
<script>
import {refundgoodsList,submintRefund} from  '@/api/refund'
import { Dialog,Notify,ImagePreview,Toast } from "vant";
export default {
    data() {
        return {
            goods:[],
            orderNum:'',
            orderType:{}
        }
    },
    created(){
        this.orderNum = this.$route.query.orderNum;
        this.orderType.s = this.$route.query.s;
        this.orderType.t = this.$route.query.t;
        this.refundgoodsList()
    },
    methods:{
        refundgoodsList() {
            refundgoodsList(this.orderNum).then(res => {
                if(res.code == 200) {
                    this.goods = res.data.orderList;
                }
            })
        },
        submintRefund() {
            let ary = this.submitData(this.goods);
            let list = ary.filter(x =>x.num != 0)
            if(list.length > 0) {
                let parmas = {
                list:list,
                orderNum: this.orderNum
                }
                submintRefund(parmas).then(res => {
                    if(res.code == 200){
                        this.jumpCheckout()
                    }
                })
            }else {
                Notify({ type: 'danger', message: '請選擇退貨商品' });
            }
        },
        plusCommodity(item) {
            if(item.refundQty < item.remainQty){
                item.refundQty++;
            }
        },
        minusCommodity(item) {
            if(item.refundQty > 0){
                item.refundQty--;
            }
        },
        changeNum(item) {
            if(item.refundQty > item.remainQty) {
                item.refundQty = item.remainQty;
            }else {
                item.refundQty = item.refundQty;
            }
        },
        jumpCheckout() {
            var that = this;
            Dialog.alert({
                title: '提示',
                message: '退款申請已經提交，請等待審核。\n 審核成功后，貨款會退到錢包。',
            }).then(() => {
                that.$router.push({path:'/orderdetail',query:{id: that.orderNum,s: that.orderType.s,t: that.orderType.t}})
            })
        },
        changeImg(e,item) {
            if ( e && e.stopPropagation ) {
                e.stopPropagation(); 
            }else {
                window.event.cancelBubble = true; 
            }
            ImagePreview([item.cover]);
        },
        submitData(data) {
            let list = [];
            data.map(item => {
                item.detailList.map(x => {
                    list.push({
                        id: x.id,
                        num: x.refundQty,
                        subOrderNum: x.subOrderNum
                    })
                })
            })
            return list;
        },
        limitTip(item){
            //'商品已全部退貨'
            Toast('可退商品數量為'+item.remainQty)
        }
    }
}
</script>
<style scoped>
.main {
    width: 93%;
    margin: auto;
    margin-top: 15px;
}

.category {
  background: #fff;
  margin: auto;
  margin-top: 15px;
  font-size: 18px;
  border-radius: 15px !important;
  /* min-height: 80px;
  line-height: 80px; */
}
.goods-card {
  margin: 0;
  background-color: #fff;
  border-radius: 15px ;
}

.text-right {
  text-align: left;
  line-height: 60px;
  font-size: 18px;
}
.total {
  margin-top: 100px;
  font-size: 18px;
}
.van-card{
  font-size: 16px;
}
.van-card__price{
  font-size: 16px;
  margin-bottom: 15px;
}
.van-card__price-integer {
    color: green;
    font-size: 18px;
}
.van-card__price-decimal {
    color: green;
    font-size: 16px;
}
.van-card__price-currency {
    color: green;
    font-size: 14px;
}
.van-button--normal {
  font-size: 18px;
}
.van-card__price {
    font-size: 18px;
}
.van-card__thumb {
  width: 2.8rem;
  height: 2.8rem;
}
.vatclass {
    font-size: 14px;
    color: #F7A92E;
}
.footer_button {
    position: fixed;
    bottom: 0;
    width: 93%;
    left: 50%;
    transform:translateX(-50%);
}
    /deep/ .van-ellipsis {
        text-overflow: initial;
        white-space: initial;
    }
    /deep/ .van-card__desc {
        max-height: initial;
        color: #646566;
        line-height: initial;
    }
</style>